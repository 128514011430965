import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { TFunction } from "next-i18next";
import Image from "next/image";
import { IJobDetailsDrawer, IMobileJobDetailsDrawer } from "magneto365.ui";
import { JobsContext, LandingContext } from "@context";
import message from "antd/lib/message";
import { useFilter, useIsWidgetToApply } from "@hooks";
import { ApplyButton } from "@components/signUp/applyButton/component";
import CustomVideo from "@components/common/customVideo/component";
import { ELazyVideo, EQueryAction, EQueryParams, EWidgetType } from "@enums/widgets.enum";
import { EVacantViews } from "@enums/vacantActionsAndViews.enum";
import { TJobQuestions, TKeyAnswer } from "#types/detail";
import {
  getFormatDistanceToNow,
  getFormattedEducationLevels,
  getFormattedExperience,
  getFormattedSalary,
  linkToShare
} from "@utils/jobOffers";
import { fillUrlUtms, getQueryParamByWindow, getOfferCompanyLink } from "@utils/url";
import { isValidUrl } from "@utils/validations";
import { settingsVideoOptions } from "@constantslanding.constant";
import { PlayCircle } from "iconsax-react";
import styles from "../../components/jobOffers/offerDetail/offerDetail.module.scss";
import { getFormattedKeywords } from "@utilsjobOffers/getFormattedKeywords.util";
import { transformSlugToCityOffer } from "../../utils/jobOffers/citySlugToCityOffer.util";

const DetailQuestionnaire = dynamic(
  () => import("@components/jobOffers/offerDetail/children/detailQuestionnaire/component"),
  { ssr: false }
);

type TProps = {
  t: TFunction;
  listMode: boolean;
  isAuthenticated: boolean;
  showOpenTabButton?: null;
  typeTitle?: "MainTitle" | "SubTitle" | undefined;
};

type TUseMagnetoDetailReturn = {
  jobDetailsDrawerProps: IJobDetailsDrawer;
  mobileJobDetailsDrawerProps: IMobileJobDetailsDrawer;
  jobDetailAction: JSX.Element | undefined;
};

const useMagnetoJobDetail = ({
  t,
  isAuthenticated,
  listMode,
  showOpenTabButton,
  typeTitle = "SubTitle"
}: TProps): TUseMagnetoDetailReturn => {
  const [answers, setAnswers] = useState<TKeyAnswer>({});

  const { query } = useRouter();
  const { setJobDetailAction, changeOfferState, applyJobWithAnswer } = useFilter();
  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);
  const context = useContext(JobsContext);
  const selectedJob = context.selectedJob;
  const jobDetailAction = context.jobDetailAction;
  const jobQuestions = context.jobQuestions as TJobQuestions;
  const jobOffersRows = context.searchResponse.rows;
  const jobOfferIndex = jobOffersRows.findIndex((offer) => offer.id === selectedJob?.id);
  const { landingPage } = useContext(LandingContext);

  useEffect(() => {
    const action = jobDetailAction ? jobDetailAction : getQueryParamByWindow(EQueryParams.action);
    if (action !== EQueryAction.save || !isAuthenticated || selectedJob?.isSaved) return;
    setJobDetailAction(null);
    changeOfferState(
      selectedJob && selectedJob?.id,
      listMode ? EVacantViews.listMode : EVacantViews.detailPage,
      selectedJob && selectedJob?.isSaved
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, jobDetailAction]);

  const similarJobsCard = context.similarVacancies.map(
    ({
      experienceMonthsNumber,
      companyLogo,
      jobSlug,
      salary,
      minSalary,
      maxSalary,
      toAgree,
      currencyCode,
      ...rest
    }) => {
      return {
        ...rest,
        salary: getFormattedSalary({ salary, minSalary, maxSalary, toAgree, currency: currencyCode, t }),
        jobUrlSlug: fillUrlUtms(
          linkToShare(
            !landingPage
              ? t("routes:jobsView", { jobSlug })
              : t("routes:jobsViewByCompany", { companySlug: landingPage.mainCompanySlug, jobSlug })
          ),
          query
        ),
        experience: experienceMonthsNumber ? getFormattedExperience(experienceMonthsNumber, t) : null,
        companyLogo: companyLogo.url
      };
    }
  );

  const similarJobsProps = useMemo(() => {
    return {
      moreJobsLink: "#",
      moreJobsText: t("jobOffers:offer.seeAllSimilarJobs"),
      sectionTitle: t("jobOffers:offer.similarJobs"),
      hideLogo: !!landingPage,
      similarJobsCard
    };
  }, [t, similarJobsCard, landingPage]);

  const showCopiedMessage = useCallback((messageText: string) => {
    message.success(messageText);
  }, []);

  const cancelApplyQuestions = useCallback(() => {
    setAnswers({});
    setJobDetailAction(null);
    // clearJobQuestions()
  }, [setJobDetailAction]);

  const jobVideo = useMemo(() => {
    const videoConfig = {
      ...settingsVideoOptions,
      src: selectedJob?.videoInformative?.url,
      poster: selectedJob?.videoInformative?.smallThumbnailUrl
    };

    return (
      selectedJob?.videoInformative && {
        imageProps: {
          image: selectedJob?.videoInformative?.smallThumbnailUrl,
          alt: "poster-video",
          playIcon: <PlayCircle />
        },
        videoId: selectedJob?.id,
        vjsOptions: videoConfig,
        VideoComponent: CustomVideo,
        videoClassName: ELazyVideo.jobDetails
      }
    );
  }, [selectedJob?.videoInformative, selectedJob?.id]);

  const jobDetailViewAction = useMemo(() => {
    if (!selectedJob) return undefined;
    if (jobDetailAction && !isAuthenticated) {
      const JobDetailAction = dynamic(() => import("@components/jobOffers/detailAction/component"));
      return <JobDetailAction />;
    }
    if (jobDetailAction && jobQuestions?.id) {
      const detailQuestionnaireProps = {
        answers,
        jobQuestions,
        selectedJobId: selectedJob?.id,
        isMagnetoUIQuestionnaire: true,
        applyJobWithAnswer,
        setAnswers,
        cancelApplyQuestions,
        t
      };
      return (
        <DetailQuestionnaire {...detailQuestionnaireProps} selectedJob={selectedJob} jobOfferIndex={jobOfferIndex} />
      );
    }
  }, [
    answers,
    isAuthenticated,
    jobDetailAction,
    jobOfferIndex,
    jobQuestions,
    selectedJob,
    applyJobWithAnswer,
    cancelApplyQuestions,
    t
  ]);

  const detailUrl = useMemo(() => {
    const url = query.companySlug
      ? `${t("routes:jobsByCompany", { companySlug: query.companySlug })}/${selectedJob?.jobSlug}`
      : t("routes:jobsView", { jobSlug: selectedJob?.jobSlug });

    return fillUrlUtms(linkToShare(url), query);
  }, [query, selectedJob?.jobSlug, t]);

  const urlWithUtms = useMemo(() => {
    return fillUrlUtms(t<string>("routes:landing", { companySlug: selectedJob?.companySlug }), query);
  }, [query, selectedJob?.companySlug, t]);

  const landingUrl = urlWithUtms;

  const {
    offerTitle,
    offerCompanyName,
    offerCompanyLogo,
    offerCompanyLink,
    offerDatesRange,
    offerVacanciesData,
    offerExperience,
    offerSalaryRange,
    offerDescription,
    offerSkills,
    offerEducationLevelName,
    offerApplied,
    jobId,
    offerKeywords
  } = useMemo(() => {
    const _offerTitle = selectedJob?.title;
    const _offerCompanyName = selectedJob?.companyName;
    const _offerCompanyLogo = selectedJob?.companyLogo?.url || selectedJob?.socialMediaImage?.url;
    const _offerCompanyLink = getOfferCompanyLink({
      companyName: selectedJob?.companyName,
      isConfidential: selectedJob?.confidential,
      isPrivate: selectedJob?.isPrivate,
      fullUrl: fillUrlUtms(t<string>("routes:jobs"), query)
    });
    const _offerPublishDate = selectedJob?.publishDate;
    const _offerDatesRange = getFormatDistanceToNow(_offerPublishDate as string, t);
    const _offerVacanciesQuantity = selectedJob?.numberOfQuotas;
    const _offerExperience = getFormattedExperience(selectedJob?.experienceMonthsNumber as number, t);
    const _offerSalary = selectedJob?.salary;
    const _offerMinSalary = selectedJob?.minSalary;
    const _offerMaxSalary = selectedJob?.maxSalary;
    const _offerSalaryToAgree = selectedJob?.toAgree;
    const _offerCurrencyCode = selectedJob?.currencyCode;
    const _offerDescription = selectedJob?.description;
    const _offerSkills = selectedJob?.skills;
    const _offerEducationLevelName = selectedJob && getFormattedEducationLevels({ t }, selectedJob?.educationLevel);
    const _offerApplied = selectedJob?.isApplied;
    const _jobId = selectedJob?.id;
    const _keywords = selectedJob?.keywords;

    const _companyLogoImage = isValidUrl(_offerCompanyLogo) ? _offerCompanyLogo : undefined;

    const _offerVacanciesData = `${t<string>("jobOffers:offer.vacanciesQuantity", {
      count: _offerVacanciesQuantity || 0
    })}`;

    const _offerSalaryRange = getFormattedSalary({
      salary: _offerSalary,
      minSalary: _offerMinSalary,
      maxSalary: _offerMaxSalary,
      toAgree: _offerSalaryToAgree,
      currency: _offerCurrencyCode,
      t
    });

    return {
      offerTitle: _offerTitle,
      offerCompanyName: _offerCompanyName,
      offerCompanyLogo: _companyLogoImage,
      offerCompanyLink: _offerCompanyLink,
      offerDatesRange: _offerDatesRange,
      offerVacanciesData: _offerVacanciesData,
      offerExperience: _offerExperience,
      offerSalaryRange: _offerSalaryRange,
      offerDescription: _offerDescription,
      offerSkills: _offerSkills,
      offerEducationLevelName: _offerEducationLevelName,
      offerApplied: _offerApplied,
      jobId: _jobId,
      offerKeywords: getFormattedKeywords(t, _keywords)
    };
  }, [selectedJob, query, t]);

  const displayLinkCompany = useMemo(() => {
    if (isWidgetIframe) return <Fragment />;
    return (
      <a href={landingUrl} title={`${selectedJob?.companyName}`} target="_blank" rel="noreferrer">
        {!!offerCompanyLogo && (
          <Image
            alt={`${selectedJob?.companyName}`}
            src={offerCompanyLogo}
            objectFit="contain"
            width={100}
            height={100}
            loading="lazy"
          />
        )}
      </a>
    );
  }, [isWidgetIframe, landingUrl, selectedJob?.companyName, offerCompanyLogo]);

  const offerCompanyImage = useMemo(
    () =>
      selectedJob && selectedJob.confidential ? (
        offerCompanyLogo && (
          <Image
            alt={`Confidencial`}
            src={offerCompanyLogo}
            objectFit="contain"
            width={100}
            height={100}
            loading="lazy"
          />
        )
      ) : (
        <Fragment>{displayLinkCompany}</Fragment>
      ),
    [displayLinkCompany, offerCompanyLogo, selectedJob]
  );

  const citiesFormatted = useMemo(() => {
    const citiesSlug = selectedJob?.citiesSlug;
    if (!citiesSlug) return [];
    const citiesNames = selectedJob?.cities;
    return transformSlugToCityOffer(citiesSlug, citiesNames, t);
  }, [selectedJob?.cities, selectedJob?.citiesSlug, t]);

  const jobDetailProps = useMemo(() => {
    const jobCompanyLogoProps = {
      jobCompanyLogoProps: {
        offerCompanyLogo: offerCompanyImage
      },
      jobHeaderProps: {
        offerTitle,
        offerCompanyName,
        offerCompanyLink,
        typeTitle
      }
    };

    const jobActionsProps = {
      externalButtonChild: offerApplied ? (
        <div className={styles["applied-label"]}>{t<string>("jobOffers:offer.alreadyApplied")}</div>
      ) : (
        <ApplyButton
          t={t}
          key={`apply-${jobId}`}
          jobOfferIndex={jobOfferIndex}
          setJobDetailAction={setJobDetailAction}
          position={1}
        />
      ),
      saveButtonProps: {
        //TODO: change the validation in magneto365.ui
        isAuthenticated: true,
        isSaved: selectedJob?.isSaved,
        onClick: () =>
          changeOfferState(
            selectedJob && selectedJob?.id,
            listMode ? EVacantViews.listMode : EVacantViews.detailPage,
            selectedJob && selectedJob?.isSaved
          ),
        addHover: true,
        buttonTitle: selectedJob?.isSaved
          ? t<string>("jobOffers:offer.savedOffer")
          : t<string>("jobOffers:actions.save")
      },
      shareButtonProps: {
        detailUrl: detailUrl,
        buttonTitle: t<string>("jobOffers:actions.share"),
        buttonText: t<string>("jobOffers:actions.share"),
        onCopySuccess: () => showCopiedMessage(t<string>("jobOffers:offer.copiedLink")),
        btnProps: {
          title: t<string>("jobOffers:actions.share")
        },
        shareLinks: [
          {
            title: t<string>("jobOffers:offer.facebookName"),
            ariaLabel: t<string>("jobOffers:actions.shareViaFacebook"),
            href: `https://www.facebook.com/sharer/sharer.php?display=page&u=${encodeURIComponent(detailUrl)}`,
            // this is for default icon.
            name: "Facebook"
          },
          {
            title: t<string>("jobOffers:offer.whatsappName"),
            ariaLabel: t<string>("jobOffers:actions.shareViaWhatsapp"),
            href: `https://api.whatsapp.com/send?text=${encodeURIComponent(
              t<string>("jobOffers:offer.checkOutThisJobOffer") + " " + detailUrl
            )}`,
            name: "WhatsApp"
          },
          {
            title: t<string>("jobOffers:offer.linkedinName"),
            ariaLabel: t<string>("jobOffers:actions.shareViaLinkedin"),
            href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(detailUrl)}`,
            name: "LinkedIn"
          },
          {
            title: t<string>("jobOffers:offer.XName"),
            ariaLabel: t<string>("jobOffers:actions.shareViaX"),
            href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(detailUrl)}`,
            name: "X"
          },
          {
            title: t<string>("jobOffers:offer.mail"),
            ariaLabel: "",
            href: `mailto:?subject=${encodeURIComponent(
              t<string>("offer.checkOutThisJobOffer")
            )}&body=${encodeURIComponent(t<string>("offer.checkOutThisJobOffer") + " " + detailUrl)}`,
            name: "Sms"
          }
        ]
      },
      actionsAnchorLinks: [
        showOpenTabButton !== null ? detailUrl : null,
        selectedJob?.isLite || selectedJob?.confidential ? null : landingUrl
      ],
      actionsAnchorTitle: [
        showOpenTabButton !== null ? t<string>("jobOffers:offer.openOfferInTab") : null,
        t<string>("jobOffers:actions.landing")
      ]
    };

    const jobDetailsProps = [
      offerDatesRange,
      offerVacanciesData,
      `${offerExperience ? offerExperience + ", " : ""}${
        offerExperience ? offerEducationLevelName && offerEducationLevelName.toLowerCase() : offerEducationLevelName
      }`,
      offerSalaryRange,
      offerKeywords
    ];

    const jobDetailCardProps = {
      offerDescription: `${offerDescription}`
    };

    const jobSkillsCardProps = {
      offerSkillsLabel: t<string>("jobOffers:offer.skills"),
      offerSkills: offerSkills
    };

    const jobApplyCardProps = {
      offerApplyHeader: t<string>("jobOffers:offer.vacancyRequirementsLabel"),
      offerApplyElements: [
        {
          offerApplyLabel: t<string>("jobOffers:offer.experienceLabel"),
          offerApplyInfo: offerExperience
        },
        {
          offerApplyLabel: t<string>("jobOffers:offer.studiesLevelLabel"),
          offerApplyInfo: offerEducationLevelName
        },
        {
          offerApplyLabel: t<string>("jobOffers:offer.salaryLabel"),
          offerApplyInfo: offerSalaryRange
        }
      ],
      externalChild: offerApplied ? (
        <div className={styles["applied-label"]}>{t<string>("jobOffers:offer.alreadyApplied")}</div>
      ) : (
        <ApplyButton
          t={t}
          key={`apply-${jobId}`}
          jobOfferIndex={jobOfferIndex}
          setJobDetailAction={setJobDetailAction}
          position={2}
        />
      )
    };

    const jobFooterCardProps = {
      offerFooterHeader: t<string>("jobOffers:actions.shareVia"),
      offerCompanyLogo: `${offerCompanyLogo}`,
      offerFooterList: [
        {
          href: `https://www.facebook.com/sharer/sharer.php?display=page&u=${encodeURIComponent(detailUrl)}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaFacebook"),
          title: t<string>("jobOffers:offer.facebookName")
        },
        {
          href: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            t<string>("jobOffers:offer.checkOutThisJobOffer") + " " + detailUrl
          )}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaWhatsapp"),
          title: t<string>("jobOffers:offer.whatsappName")
        },
        {
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(detailUrl)}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaLinkedin"),
          title: t<string>("jobOffers:offer.linkedinName")
        },
        {
          href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(detailUrl)}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaTwitter"),
          title: t<string>("jobOffers:offer.twitterName")
        },
        {
          href: `mailto:?subject=${encodeURIComponent(
            t<string>("offer.checkOutThisJobOffer")
          )}&body=${encodeURIComponent(t<string>("offer.checkOutThisJobOffer") + " " + detailUrl)}`,
          ariaLabel: "",
          title: t<string>("jobOffers:offer.mail")
        }
      ],
      shareButtonProps: {
        detailUrl: detailUrl,
        buttonTitle: t<string>("jobOffers:actions.copy"),
        onCopySuccess: () => showCopiedMessage(t<string>("jobOffers:offer.copiedLink"))
      }
    };

    return {
      jobCompanyLogoProps,
      jobActionsProps,
      jobDetailsProps,
      jobDetailCardProps,
      jobSkillsCardProps,
      jobApplyCardProps,
      jobFooterCardProps,
      similarJobsProps,
      jobVideo,
      cities: citiesFormatted,
      isRemote: Boolean(selectedJob?.isRemote),
      textRemote: t<string>("jobOffers:offer.remoteOffer")
    };
  }, [
    offerCompanyImage,
    offerTitle,
    offerCompanyName,
    offerCompanyLink,
    typeTitle,
    offerApplied,
    t,
    jobId,
    jobOfferIndex,
    setJobDetailAction,
    selectedJob,
    detailUrl,
    showOpenTabButton,
    landingUrl,
    offerDatesRange,
    offerVacanciesData,
    offerExperience,
    offerEducationLevelName,
    offerSalaryRange,
    offerDescription,
    offerSkills,
    offerCompanyLogo,
    similarJobsProps,
    changeOfferState,
    listMode,
    showCopiedMessage,
    offerKeywords,
    jobVideo,
    citiesFormatted
  ]);

  const mobileDetailProps = useMemo(() => {
    const jobCompanyLogoProps = {
      jobCompanyLogoProps: {
        offerCompanyLogo: offerCompanyImage
      },
      jobHeaderProps: {
        offerTitle,
        offerCompanyName,
        offerCompanyLink,
        isHidden: false,
        typeTitle
      }
    };

    const jobDetailsHeaderText = t<string>("jobOffers:actions.goBack");

    const jobDetailsProps = [
      offerDatesRange,
      offerVacanciesData,
      `${offerExperience ? offerExperience + ", " : ""}${
        offerExperience ? offerEducationLevelName && offerEducationLevelName.toLowerCase() : offerEducationLevelName
      }`,
      offerSalaryRange,
      offerKeywords
    ];

    const jobDetailCardProps = {
      offerDescription: `${offerDescription}`
    };

    const jobSkillsCardProps = {
      offerSkillsLabel: t<string>("jobOffers:offer.skills"),
      offerSkills: offerSkills
    };

    const jobApplyCardProps = {
      offerApplyHeader: t<string>("jobOffers:offer.vacancyRequirementsLabel"),
      offerApplyElements: [
        {
          offerApplyLabel: t<string>("jobOffers:offer.experienceLabel"),
          offerApplyInfo: offerExperience
        },
        {
          offerApplyLabel: t<string>("jobOffers:offer.studiesLevelLabel"),
          offerApplyInfo: offerEducationLevelName
        },
        {
          offerApplyLabel: t<string>("jobOffers:offer.salaryLabel"),
          offerApplyInfo: offerSalaryRange
        }
      ],
      externalChild: offerApplied ? (
        <div className={styles["applied-label"]}>{t<string>("jobOffers:offer.alreadyApplied")}</div>
      ) : (
        <ApplyButton
          t={t}
          key={`apply-${jobId}`}
          jobOfferIndex={jobOfferIndex}
          setJobDetailAction={setJobDetailAction}
        />
      )
    };

    const jobFooterCardProps = {
      offerFooterHeader: t<string>("jobOffers:actions.shareVia"),
      offerCompanyLogo: `${offerCompanyLogo}`,
      offerFooterList: [
        {
          href: `https://www.facebook.com/sharer/sharer.php?display=page&u=${encodeURIComponent(detailUrl)}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaFacebook"),
          title: t<string>("jobOffers:offer.facebookName")
        },
        {
          href: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            t<string>("jobOffers:offer.checkOutThisJobOffer") + " " + detailUrl
          )}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaWhatsapp"),
          title: t<string>("jobOffers:offer.whatsappName")
        },
        {
          href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(detailUrl)}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaLinkedin"),
          title: t<string>("jobOffers:offer.linkedinName")
        },
        {
          href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(detailUrl)}`,
          ariaLabel: t<string>("jobOffers:actions.shareViaTwitter"),
          title: t<string>("jobOffers:offer.twitterName")
        },
        {
          href: `mailto:?subject=${encodeURIComponent(
            t<string>("offer.checkOutThisJobOffer")
          )}&body=${encodeURIComponent(t<string>("offer.checkOutThisJobOffer") + " " + detailUrl)}`,
          ariaLabel: "",
          title: t<string>("jobOffers:offer.mail")
        }
      ],
      shareButtonProps: {
        detailUrl: detailUrl,
        buttonTitle: t<string>("jobOffers:actions.copy"),
        onCopySuccess: () => showCopiedMessage(t<string>("jobOffers:offer.copiedLink"))
      }
    };

    const mobileJobDetailsBarProps = {
      externalButtonChild: offerApplied ? (
        <div className={styles["applied-label"]}>{t<string>("jobOffers:offer.alreadyApplied")}</div>
      ) : (
        <ApplyButton
          t={t}
          key={`apply-${jobId}`}
          jobOfferIndex={jobOfferIndex}
          setJobDetailAction={setJobDetailAction}
        />
      ),
      actionsProps: {
        ActionsHeader: offerTitle,
        ActionAnchorText: [t<string>("jobOffers:offer.openOfferInTab"), t<string>("jobOffers:actions.landing")],
        ActionsAnchorLinks: [detailUrl, selectedJob?.isLite || selectedJob?.confidential ? null : landingUrl],
        saveButtonProps: {
          isAuthenticated: true,
          isSaved: selectedJob?.isSaved,
          onClick: () =>
            changeOfferState(
              selectedJob && selectedJob?.id,
              listMode ? EVacantViews.listMode : EVacantViews.detailPage,
              selectedJob && selectedJob?.isSaved
            ),
          buttonText: selectedJob?.isSaved
            ? t<string>("jobOffers:offer.savedOffer")
            : t<string>("jobOffers:actions.save")
        },
        copyButtonProps: {
          detailUrl: detailUrl,
          buttonText: t<string>("jobOffers:actions.copyLink"),
          onCopySuccess: () => showCopiedMessage(t<string>("jobOffers:offer.copiedLink")),
          shareLinks: [
            {
              href: `mailto:?subject=${encodeURIComponent(
                t<string>("offer.checkOutThisJobOffer")
              )}&body=${encodeURIComponent(t<string>("offer.checkOutThisJobOffer") + " " + detailUrl)}`,
              ariaLabel: "",
              title: t<string>("jobOffers:offer.mail"),
              name: "Sms"
            },
            {
              href: `https://twitter.com/intent/tweet?url=${encodeURIComponent(detailUrl)}`,
              ariaLabel: t<string>("jobOffers:actions.shareViaTwitter"),
              title: t<string>("jobOffers:offer.XName"),
              name: "X"
            },
            {
              href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(detailUrl)}`,
              ariaLabel: t<string>("jobOffers:actions.shareViaLinkedin"),
              title: t<string>("jobOffers:offer.linkedinName"),
              name: "LinkedIn"
            },
            {
              href: `https://api.whatsapp.com/send?text=${encodeURIComponent(
                t<string>("jobOffers:offer.checkOutThisJobOffer") + " " + detailUrl
              )}`,
              ariaLabel: t<string>("jobOffers:actions.shareViaWhatsapp"),
              title: t<string>("jobOffers:offer.whatsappName"),
              name: "WhatsApp"
            },
            {
              href: `https://www.facebook.com/sharer/sharer.php?display=page&u=${encodeURIComponent(detailUrl)}`,
              ariaLabel: t<string>("jobOffers:actions.shareViaFacebook"),
              title: t<string>("jobOffers:offer.facebookName"),
              name: "Facebook"
            }
          ]
        },
        shareButtonProps: {
          title: t<string>("jobOffers:actions.share"),
          content: t<string>("jobOffers:actions.share")
        },
        externalButtonChild: offerApplied ? (
          <div className={styles["applied-label"]}>{t<string>("jobOffers:offer.alreadyApplied")}</div>
        ) : (
          <ApplyButton
            t={t}
            key={`apply-${jobId}`}
            jobOfferIndex={jobOfferIndex}
            setJobDetailAction={setJobDetailAction}
          />
        ),
        backText: t<string>("jobOffers:actions.goBack"),
        offerCompanyName
      }
    };

    return {
      jobCompanyLogoProps,
      jobDetailsHeaderText,
      jobDetailsProps,
      jobDetailCardProps,
      jobSkillsCardProps,
      jobApplyCardProps,
      jobFooterCardProps,
      mobileJobDetailsBarProps,
      similarJobsProps,
      jobVideo,
      cities: citiesFormatted,
      isRemote: Boolean(selectedJob?.isRemote),
      textRemote: t<string>("jobOffers:offer.remoteOffer")
    };
  }, [
    offerCompanyImage,
    offerTitle,
    offerCompanyName,
    offerCompanyLink,
    typeTitle,
    t,
    offerDatesRange,
    offerVacanciesData,
    offerExperience,
    offerEducationLevelName,
    offerSalaryRange,
    offerDescription,
    offerSkills,
    offerApplied,
    jobId,
    jobOfferIndex,
    setJobDetailAction,
    offerCompanyLogo,
    detailUrl,
    selectedJob,
    landingUrl,
    similarJobsProps,
    showCopiedMessage,
    changeOfferState,
    listMode,
    offerKeywords,
    jobVideo,
    citiesFormatted
  ]);

  return {
    jobDetailsDrawerProps: jobDetailProps,
    mobileJobDetailsDrawerProps: mobileDetailProps,
    jobDetailAction: jobDetailViewAction
  };
};

export default useMagnetoJobDetail;
